import React from 'react';
import { MDBInput, MDBSelect } from 'mdbreact';
import './CustomMessageField.scss';
interface Props {
	type: string;
	disabled?: boolean;
	icon: string;
	input: {
		value: any;
		onChange(value: any): void;
	};
	label?: string;
	meta: {
		asyncValidating: boolean;
		error: string;
		touched: boolean;
	};
}

const InputField = (props: Props) => {
	const {
		disabled,
		type,
		icon,
		input,
		label,
		meta: { asyncValidating, touched, error },
	} = props;

	let validClass = '';
	if (touched && error) {
		validClass = 'is-invalid';
	} else if (touched) {
		validClass = 'is-valid';
	}

	// const handleChange = (e: any) => {
	// 	const temp = (input.value += e.target.value);
	// 	input.onChange(temp);
	// };

	const handleSelect = (text: string) => {
		if (text[0] === undefined) return;
		const temp = (input.value += text[0]);
		input.onChange(temp);
	};

	const options = [
		{ text: 'First Name', value: '{{firstname}}' },
		{ text: 'Last Name', value: '{{lastname}}' },
		{ text: 'Phone Number', value: '{{phonenumber}}' },
		{ text: 'Zip Code', value: '{{zipcode}}' },
		{ text: 'Medigap Click URL', value: '{{medigapClickUrl}}' },
	];

	return (
		<div className='no-margin input-field'>
			<MDBSelect options={options} label='Custom Values' getValue={handleSelect} />
			<MDBInput
				type={type}
				icon={icon}
				label={label}
				className={validClass}
				disabled={disabled}
				value={typeof input.value === 'number' ? `${input.value}` : input.value}
				onChange={input.onChange}
			>
				{asyncValidating && (
					<div className='spinner-border spinner-border-sm' role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				)}
				<div className='invalid-feedback'>{error}</div>
			</MDBInput>
		</div>
	);
};

InputField.defaultProps = {
	icon: null,
	type: 'text',
	disabled: false,
};

export default InputField;
