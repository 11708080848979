import { connect } from 'react-redux'

import { getNumbers } from 'store/twilio/reducer'
import TransferOptionForm from './TransferOptionForm'

const mapStateToProps = (state, props) => ({
  twilioNumbers: getNumbers(state),
  ...props
})

export default connect(mapStateToProps)(TransferOptionForm)
