import { connect } from 'react-redux'

import { getNumbers } from 'store/twilio/reducer'
import NumberForm from './NumberForm'

const mapStateToProps = (state, props) => ({
  twilioNumbers: getNumbers(state),
  ...props
})

export default connect(mapStateToProps)(NumberForm)
