import React from 'react'
import { MDBSpinner } from 'mdbreact'

const Spinner = () => (
  <div className="spinner-container">
    <MDBSpinner />
  </div>
)

export default Spinner
